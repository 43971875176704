<template>
  <div>
    <div class="mt-4">
      <template>
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="estacionamentos"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Estacionamentos registrados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-icon
                  v-if="canList"
                  medium
                  @click="atualizarListaEstacionamento"
                  title="Atualizar"
                  >mdi-refresh</v-icon
                >
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="canCreate && allowedToAdd"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                      >Novo estacionamentos</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.descricao"
                              label="Descrição"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancelar</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save"
                        >Salvar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="canUpdate"
                small
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon v-if="canUpdate" small @click="goToEstacionamento(item)"
                >mdi-eye</v-icon
              >
            </template>

            <template v-slot:no-data>
              <v-btn v-if="canList" color="primary" @click="atualizarListaEstacionamento"
                >Reset</v-btn
              >
              <div v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </div>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
// import { ModalCriarEditarPatio } from './ModalCriarEditarPatio'
import { mapActions, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  data() {
    return {
      dialog: false,
      tab: null,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        descricao: '',
      },
      defaultItem: {
        descricao: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['estacionamentos']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Estacionamento.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },

    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.editedIndex === -1
        ? 'Novo Estacionamento'
        : 'Editar Estacionamento'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.atualizarListaEstacionamento()
    }
  },

  methods: {
    ...mapActions('patio', [
      'getEstacionamentos',
      'addEstacionamento',
      'editEstacionamento',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async atualizarListaEstacionamento() {
      try {
        await this.getEstacionamentos()
      } catch (error) {
        if (error.response && error.response.status == 403) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    goToEstacionamento(item) {
      this.$router.push({
        name: 'Setores',
        params: {
          estacionamento: item.public_id,
        },
      })
    },
    editItem(item) {
      this.editedIndex = this.estacionamentos.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedIndex > -1) {
        this.editEstacionamento(this.editedItem) // REVER.. Editar
      } else {
        this.addEstacionamento(this.editedItem)
      }
      this.close()

      setTimeout(() => {
         this.atualizarListaEstacionamento()
      }, 300)
    },
  },
}
</script>
