<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Estacionamento">
          <listar-estacionamento />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarEstacionamento from '../../components/patio/ListarEstacionamento.vue'
export default {
  data() {
    return {
      page: {
        title: 'Gerenciamento de Estacionamento',
      },
      breadcrumbs: [
        {
          text: 'Estacionamento',
          disabled: false,
          to: '/patio/Estacionamento',
        },
        {
          text: 'Cadastro',
          disabled: true,
        },
      ],
    }
  },
  components: {
    ListarEstacionamento,
  },
}
</script>

